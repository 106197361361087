<template>
  <div v-if="fullscreen"
       class="screen-cover">
    <div class="spinner overlay"></div>
  </div>
  <div v-else-if="overlay"
       class="element-background">
    <div class="spinner overlay"></div>
  </div>
  <div v-else
       class="spinner"></div>
</template>

<script>
  export default {
    name: "LoadingIndicator",
    props: {
      fullscreen: {
        default: false,
        required: false,
        type: Boolean
      },
      overlay: {
        default: false,
        required: false,
        type: Boolean
      }
    },
    data() {
      return {}
    }
  }
</script>

<style scoped lang="scss">
  .spinner {
    margin: 1em auto;
    height: var(--spinner-size, 50px);
    width: var(--spinner-size, 50px);
    border: var(--spinner-weight, 3px) solid;
    border-color: var(--color-bright) transparent;
    border-radius: var(--spinner-size, 50px);
    z-index: 220;
    animation: spin 1s infinite linear;
  }

  .overlay {
    position: absolute;
    top: calc(var(--top-offset, 50%) - (var(--spinner-size, 50px) / 2));
    left: calc(50% - (var(--spinner-size, 50px) / 2));
  }

  .screen-cover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 200;
    background-color: rgba(255, 255, 255, var(--bg-opacity, 0.2));
  }

  .element-background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 200;
    background-color: rgba(255, 255, 255, var(--bg-opacity, 0.5));
  }

  @keyframes spin {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }
</style>
